

#news-columns {
	column-width: 300px;
	column-gap: 15px;
    width: 100%;
	max-width: 1700px;
	margin: 0px auto;
}


div#news-columns figure {
	background: #fefefe;
    background: #131212;
background: #2e2b2b;
	border: 0px solid #fcfcfc;
	box-shadow: 0 0px 0px rgba(34, 25, 25, 0.4);
	margin: 0 0px 15px;
	padding: 0px;
	padding-bottom: 10px;
	transition: opacity .4s ease-in-out;
  display: inline-block;
  column-break-inside: avoid;
}

div#news-columns figure img {
	width: 100%; height: auto;
	border-bottom: 0px solid #ccc;
	padding-bottom: 10px;
	margin-bottom: 5px;
}

div#news-columns figure figcaption {
  font-size: 1.1rem;
	color: #444;
  color: whitesmoke;
  color:#929496;
  color: #d3d4d6;
  line-height: 1.2;
  font-style: ;
  font-family: 'DejaVu Serif Bold';
  font-weight: bold;
  padding: 0px 15px;
}

div#news-columns small { 
  font-size: 1rem;
  float: right; 
  text-transform: uppercase;
  color: #aaa;
} 

div#news-columns small a { 
  color: #666;
  color: whitesmoke; 
  text-decoration: none; 
  transition: .4s color;
}

div#news-columns:hover figure:not(:hover) {
	opacity: 0.4;
}
.news_mode_btn {
  font-family: 'DejaVu Serif Bold';
  margin-right:3%;
   cursor:pointer;
   font-weight: bold;
}

@media screen and (max-width: 750px) { 
  #news-columns { column-gap: 0px; }
  #news-columns figure { width: 100%; }
}
