.sci-converse-container{
    display: flex;
    max-height: 70vh;
    overflow: scroll;
    flex-direction: column;
}
.sci-converse-head{
    /* background-color: rgb(2, 2, 34); */
    margin:0;
    /* padding: 3px 10px; */
    color: white;
    display: flex;
    height: 35px;
    
}
#converseBar{
    height: 1.5rem;
    color: white;
}
#sci-converse-client-message{
    margin: 2px 10% 2px 1%;
    padding: 2px 10px;
    background-color:#d6d5cb;
    display: inline-block;
}
#sci-converse-response{
    margin: 2px 10% 2px 10%;
    padding: 2px 10px;
    background-color:#cac7c3;
    border-radius: 2px;
    display: inline-block;

}
#sci-converse-response-item{

}
#sci-converse-response > div:nth-child(even) p{
background:lightgray;
}
.sci-converse-client-input-container{
/*   background-color:rgb(217, 217, 210);
 border-bottom: 3px solid rgb(2, 2, 32); */
display: inline-flex;
margin-left: 10px;
padding-right:10px ;
border-radius: 2px;
}
.sci-converse-client-input{
    margin-left: 1px;
    border-radius: 2px;
   /*  background-color:rgb(217, 217, 210); */
    width: 30vw;
    color: whitesmoke;
    padding: 1px 5px;
}