
.web-container{
  /* margin-bottom: 10px; */
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: scroll;
}
#web-columns {
	column-width: 300px;
	column-gap: 15px;
    width: 100%;
	max-width: 1700px;
	margin: 0px auto;
}


div#web-columns figure {
	background: #fefefe;
    background: #131212;
background: #2e2b2b;
	border: 0px solid #fcfcfc;
	box-shadow: 0 0px 0px rgba(34, 25, 25, 0.4);
	margin: 0 0px 15px;
	padding: 0px;
	padding-bottom: 10px;
	transition: opacity .4s ease-in-out;
  display: inline-block;
 /*  column-break-inside: avoid; */
    width: 100%;
  
}

div#web-columns figure img {
	width: 60%; height: auto;
	border-bottom: 0px solid #ccc;
	padding-bottom: 10px;
  margin-bottom: 5px;
  float: left;
  margin: 0px 10px 10px 0;
}

#web_converse{
  height: 1.5rem;
  margin-left: 5rem;
  color: white;
}
#converseBar{
  height: 1.5rem;
  color: white;
}
#web-columns {
	column-width: 600px;
	column-gap: 15px;
    width: 100%;
	max-width: 1700px;
	margin: 0px auto;
}
.web_btn {
 padding: .2rem .25rem; 
 color: whitesmoke;
  background-color:#272828;
  font-size: 16;
  font-weight: bold;
  margin: .25rem;
  border-radius: 1px;
  transition: .3s ease-in-out;
  cursor: pointer;
}
.web_btn:hover {
  opacity: .8;
    color: yellowgreen;
    border:1px solid whitesmoke;
}
.web_link{
  margin-left: 7px;
  display: inline;
}
.web_link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(177, 105, 23);
}
#web_tool_container{
  display: flex;
  flex-direction: row;
  background-color: #092039;
  color: whitesmoke;
  padding:0 20px;
  align-items: center;
  margin-bottom: 15px;
  }


div#web-columns figure figcaption {
  font-size: 1rem;
	color: #444;
  color: whitesmoke;
  color:#929496;
  color: #d3d4d6;
  line-height: 1.2;
  font-style: ;
  font-weight: bold;
  padding: 0px 15px;
}

div#web-columns small { 
  font-size: 1rem;
  float: right; 
  text-transform: uppercase;
  color: #aaa;
} 

div#web-columns small a { 
  color: #666;
  color: whitesmoke; 
  text-decoration: none; 
  transition: .4s color;
}

div#web-columns:hover figure:not(:hover) {
	opacity: 0.4;
}
#bok_subject {
  font-size:1.5rem;
  /* color:rgb(191, 196, 21); 
  color: whitesmoke;*/
  text-align:;
  padding:10px;
   width:100%;
   border:1px solid whitesmoke;
  /* margin:auto; */
margin-bottom: 1vh;}


@media screen and (max-width: 750px) { 
  #web-columns { column-gap: 0px; }
  #web-columns figure { width: 100%; }
}