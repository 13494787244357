@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

account-h1 {
  font-weight: bold;
  margin: 0;
}

account-h2 {
  text-align: center;
}

account-p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

account-button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

account-button:active {
  transform: scale(0.95);
}

account-button:focus {
  outline: none;
}

account-button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.account-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  font-family: "Montserrat", sans-serif;
  margin: auto;
}

.form-account-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-account-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.account-container.right-panel-active .sign-in-account-container {
  transform: translateX(100%);
}

.sign-up-account-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.account-container.right-panel-active .sign-up-account-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s;
          animation: show 0.6s;
}

@-webkit-keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-account-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.account-container.right-panel-active .overlay-account-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ff416c;
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.account-container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.account-container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.account-container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-account-container {
  margin: 20px 0;
}

.social-account-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer account-p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}

#modal {
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  width: 300px;
  height: 400px;
  z-index: 1010;
  display: block;
}
.model-content {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* for example... */

  display: block;
}
.close-model {
  display: none;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box
}
body {
    font-family: 'DejaVu Serif Bold', 'Oswald', 'sans-serif';
    font-size: ;
    background: #131212;
    background: #212020;
}

a {
    color: #333;
    color: whitesmoke;
    text-decoration: none;
}
.map_select {
    display: inline;
    height: 1.5rem;
    padding: 0;
}
#map_range_input{
    margin:0;
    border: none;
}
.sci-main-container {
    display: grid;
    grid-template-columns:  minmax(200px, 250px) 1fr minmax(250px, 250px);
    grid-template-rows: 1fr;
    grid-gap: 0px;
  /*   background: #f1f1f1; */
  background: #131212;
  background: #212020;

    margin-bottom: 1px;
    grid-template-areas: "left-nav sci-content right-nav"; 
}
#left-nav {
    grid-area: left-nav;
    position: -webkit-sticky;
    position: sticky;
    top: 5vh;
    height: 85vh;
    box-sizing: border-box;
}
#right-nav{
    grid-area: right-nav;
    position: -webkit-sticky;
    position: sticky;
    top: 5vh;
    height: 90vh;
    box-sizing: border-box;
    overflow: scroll;
}

.sci-container {
    overflow: auto;
    padding: 0 0rem;
    grid-area: sci-content;
}
.main-header {
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
}

.main-header h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    line-height: 1.2;
}
.main-header h1 span{
    color: royalblue;
}
.main-header p {
    font-size: 2rem;
}

.sci-card-content{
padding:17px 10px 17px 20px;
font-family:Helvetica, Arial, sans-serif;
font-size:14px;
line-height:18px;
color:#292F33;
}

.sci-card {
    display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 2rem;
    background: #131212;
   /*  background: #f1f1f1; */
    margin-bottom: 1px;
    grid-template-areas: "content content image"; 
}
.sci-card h3 {
    margin-bottom: 2rem;
}
.sci-card>  img {
    grid-area: image;
    max-height: 250px;
}
.sci-card>.sci-card-content {
    grid-area: content;
}
.sci-card:nth-child(2n) {
    grid-template-areas: "image content content"
}

/* 
.sci-card:nth-child(even) div {
grid-column: -1/-3;
} 
.sci-card:nth-child(odd) img {
order: 2;
} */

.sci-btn-top-head{
    display: inline-block;
    border-style: none 1px none none;
    color:rgba(17, 8, 42, 0.9);
    color: whitesmoke;
    padding:  .2rem ;
    margin: 2px;
    background:#d0d0c8;
    background: #4d4c4c;
   /*  background: #212020; */
    cursor: pointer;
    transition: all .6s;
    letter-spacing: 1.3px;
    border-right: 1px solid gray;
    text-transform: capitalize;
    font-size: 15px;
    
    
}
.sci-btn-top-head:hover {
    border: 1px solid gray;
    
}
.sci-btn {
    display: inline-block;
    background: whitesmoke;
    background: #131212;
    background: #2b2828;
   /*  background: #212020; */
    color: rgba(6, 5, 97, 0.897);
    color: whitesmoke;
    padding:  .2rem ;
    cursor: pointer;
    border-style: none 1px none none;
    border-color: white;
    border-radius: 1px;
}
.sci-btn:hover {
    opacity: .8;
    color: red;
    color: yellowgreen;
    border-style: solid;
    border-color: whitesmoke;
    
}
@media(max-width: 700px) {
    .sci-card{
        display: block;
    }
}
.news-container{
    /* margin-bottom: 10px; */
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow: scroll;
}

#news_heads {
    background-color:whitesmoke;
    background: #131212;
    background:#212020 ;

    font-weight: bold;
    padding-top: -2vh;
    height: 100vh;
}
#news_heads a {
    color: #1940b4;
    color: whitesmoke;
    font-weight: bold;
}
.news_head{
    text-transform: capitalize;
    text-decoration:none;
}
.news_head_btn{
    background: white;
    background: #131212;
    background: #201d1d;
    background:#212020 ;
    background: #272828;
    color: #000;
    color: whitesmoke;
    float: left;
    font-weight: 500;
    line-height: 100%;
    font-size: 12px;;
    padding: 8px 10px;
    text-transform: capitalize;
    transition: all .6s;
    letter-spacing: 1.3px;
    font-family: 'Open Sans', sans-serif;
    margin: 2px;
    cursor: pointer;
    opacity: .75;
}
.top-nav-btn{
    /* background: white;
    background-color: black; */
    color: #000;
    color: whitesmoke;
    float: left;
    font-weight: 600;
    line-height: 100%;
    /* font-size: 15px;; */
   padding: 5px 0px;
    text-transform: capitalize;
    transition: all .6s;
    letter-spacing: 1.3px;
    font-family: 'Open Sans', sans-serif;
    margin: 10px 2rem;
    cursor: pointer;
}
.top-nav-btn:hover {
    border-bottom: 2px solid rgb(38, 107, 32);
    /* background-color: #d4d447; */
}
.main_news_head {
    font-size: 18px;
    margin-top: 10px;
    background: #131212;
    background:#212020 ;
    cursor: pointer;
    font-family: 'DejaVu Serif Bold';
    
}
#news_card_content{
padding:5px 10px 5px 15px;
font-family:Helvetica, Arial, sans-serif;
font-size:14px;
line-height:18px;
color:#292F33;
color: #C8D5DD;
}

#news_card_title{
padding:0px;
font-family:Helvetica, Arial, sans-serif;
font-size: 16px;
/* line-height:20px;
font-weight:bold; */
color:rgb(62, 65, 66);
color:#C8D5DD;
font-style: italic
}

#searchBar, #urlBar, #wikiBar,#webBar, #predictiveBar {
    color: whitesmoke;
}
#form1{
  margin-left:200px;
}
.side-nav li a {
  text-decoration:none;
}
#searchResults2{
  background-color:#666666;
}
#searchResults{
  background-color:#666666;
}
.right li a{
  padding-right:2em;
  text-decoration:none;
  color: black;
}
.right li {
    margin-top: 5px;
    padding: 5px;
    font-size: 1rem;
}

@font-face{font-family:'Calluna';
 src:url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/callunasansregular-webfont.woff') format('woff');
}
body {
	background: url(//subtlepatterns.com/patterns/scribble_light.png);
  font-family: 'Calluna', 'Arial', 'sans-serif';
  min-height: 1000px;
}
#searchBar2{
  height: 1.5rem;
  margin-top: 15px;
}

.input_field_2{
   max-width: "900pt";
   height:40; min-width: "25vw"; color:"whitesmoke"; display: "flex"; margin-right: "2vw" }


#news-columns {
	-webkit-column-width: 300px;
	        column-width: 300px;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
    width: 100%;
	max-width: 1700px;
	margin: 0px auto;
}


div#news-columns figure {
	background: #fefefe;
    background: #131212;
background: #2e2b2b;
	border: 0px solid #fcfcfc;
	box-shadow: 0 0px 0px rgba(34, 25, 25, 0.4);
	margin: 0 0px 15px;
	padding: 0px;
	padding-bottom: 10px;
	transition: opacity .4s ease-in-out;
  display: inline-block;
  column-break-inside: avoid;
}

div#news-columns figure img {
	width: 100%; height: auto;
	border-bottom: 0px solid #ccc;
	padding-bottom: 10px;
	margin-bottom: 5px;
}

div#news-columns figure figcaption {
  font-size: 1.1rem;
	color: #444;
  color: whitesmoke;
  color:#929496;
  color: #d3d4d6;
  line-height: 1.2;
  font-style: ;
  font-family: 'DejaVu Serif Bold';
  font-weight: bold;
  padding: 0px 15px;
}

div#news-columns small { 
  font-size: 1rem;
  float: right; 
  text-transform: uppercase;
  color: #aaa;
} 

div#news-columns small a { 
  color: #666;
  color: whitesmoke; 
  text-decoration: none; 
  transition: .4s color;
}

div#news-columns:hover figure:not(:hover) {
	opacity: 0.4;
}
.news_mode_btn {
  font-family: 'DejaVu Serif Bold';
  margin-right:3%;
   cursor:pointer;
   font-weight: bold;
}

@media screen and (max-width: 750px) { 
  #news-columns { grid-column-gap: 0px; -webkit-column-gap: 0px; column-gap: 0px; }
  #news-columns figure { width: 100%; }
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(44, 43, 43);
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 10px 7px;
    margin-top: 10px;
    margin-left: -45px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
.sci-converse-container{
    display: flex;
    max-height: 70vh;
    overflow: scroll;
    flex-direction: column;
}
.sci-converse-head{
    /* background-color: rgb(2, 2, 34); */
    margin:0;
    /* padding: 3px 10px; */
    color: white;
    display: flex;
    height: 35px;
    
}
#converseBar{
    height: 1.5rem;
    color: white;
}
#sci-converse-client-message{
    margin: 2px 10% 2px 1%;
    padding: 2px 10px;
    background-color:#d6d5cb;
    display: inline-block;
}
#sci-converse-response{
    margin: 2px 10% 2px 10%;
    padding: 2px 10px;
    background-color:#cac7c3;
    border-radius: 2px;
    display: inline-block;

}
#sci-converse-response-item{

}
#sci-converse-response > div:nth-child(even) p{
background:lightgray;
}
.sci-converse-client-input-container{
/*   background-color:rgb(217, 217, 210);
 border-bottom: 3px solid rgb(2, 2, 32); */
display: inline-flex;
margin-left: 10px;
padding-right:10px ;
border-radius: 2px;
}
.sci-converse-client-input{
    margin-left: 1px;
    border-radius: 2px;
   /*  background-color:rgb(217, 217, 210); */
    width: 30vw;
    color: whitesmoke;
    padding: 1px 5px;
}
.map-container {
    position: absolute;
    width: 80%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 10%;
    margin-top: 10%;
  }
  
  .sidebarStyle {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
  }

.web-container{
  /* margin-bottom: 10px; */
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: scroll;
}
#web-columns {
	-webkit-column-width: 300px;
	        column-width: 300px;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
    width: 100%;
	max-width: 1700px;
	margin: 0px auto;
}


div#web-columns figure {
	background: #fefefe;
    background: #131212;
background: #2e2b2b;
	border: 0px solid #fcfcfc;
	box-shadow: 0 0px 0px rgba(34, 25, 25, 0.4);
	margin: 0 0px 15px;
	padding: 0px;
	padding-bottom: 10px;
	transition: opacity .4s ease-in-out;
  display: inline-block;
 /*  column-break-inside: avoid; */
    width: 100%;
  
}

div#web-columns figure img {
	width: 60%; height: auto;
	border-bottom: 0px solid #ccc;
	padding-bottom: 10px;
  margin-bottom: 5px;
  float: left;
  margin: 0px 10px 10px 0;
}

#web_converse{
  height: 1.5rem;
  margin-left: 5rem;
  color: white;
}
#converseBar{
  height: 1.5rem;
  color: white;
}
#web-columns {
	-webkit-column-width: 600px;
	        column-width: 600px;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
    width: 100%;
	max-width: 1700px;
	margin: 0px auto;
}
.web_btn {
 padding: .2rem .25rem; 
 color: whitesmoke;
  background-color:#272828;
  font-size: 16;
  font-weight: bold;
  margin: .25rem;
  border-radius: 1px;
  transition: .3s ease-in-out;
  cursor: pointer;
}
.web_btn:hover {
  opacity: .8;
    color: yellowgreen;
    border:1px solid whitesmoke;
}
.web_link{
  margin-left: 7px;
  display: inline;
}
.web_link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(177, 105, 23);
}
#web_tool_container{
  display: flex;
  flex-direction: row;
  background-color: #092039;
  color: whitesmoke;
  padding:0 20px;
  align-items: center;
  margin-bottom: 15px;
  }


div#web-columns figure figcaption {
  font-size: 1rem;
	color: #444;
  color: whitesmoke;
  color:#929496;
  color: #d3d4d6;
  line-height: 1.2;
  font-style: ;
  font-weight: bold;
  padding: 0px 15px;
}

div#web-columns small { 
  font-size: 1rem;
  float: right; 
  text-transform: uppercase;
  color: #aaa;
} 

div#web-columns small a { 
  color: #666;
  color: whitesmoke; 
  text-decoration: none; 
  transition: .4s color;
}

div#web-columns:hover figure:not(:hover) {
	opacity: 0.4;
}
#bok_subject {
  font-size:1.5rem;
  /* color:rgb(191, 196, 21); 
  color: whitesmoke;*/
  text-align:;
  padding:10px;
   width:100%;
   border:1px solid whitesmoke;
  /* margin:auto; */
margin-bottom: 1vh;}


@media screen and (max-width: 750px) { 
  #web-columns { grid-column-gap: 0px; -webkit-column-gap: 0px; column-gap: 0px; }
  #web-columns figure { width: 100%; }
}
Upload {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
  }
  
  .Content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .Files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1 1;
    overflow-y: auto;
  }
  
  .Actions {
    display: flex;
    flex: 1 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 32px;
  }
  
  .Title {
    margin-bottom: 32px;
    color: #555;
  }
  .Filename {
    margin-bottom: 8px;
    font-size: 16px;
    color: #555;
  }
  
  .Row {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .CheckIcon {
    opacity: 0.5;
    margin-left: 32px;
  }
  
  .ProgressWrapper {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
  }
  #upload-btn {
    font-family: 'Roboto medium', sans-serif;
    font-size: 14px;
    display: inline-block;
    height: 36px;
    min-width: 88px;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 2px;
    background: rgba(103, 58, 183, 1);
    color: #fff;
    outline: 0;
  }
  
  #upload-btn:disabled {
    background: rgb(189, 189, 189);
    cursor: default;
  }

