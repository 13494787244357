@font-face{font-family:'Calluna';
 src:url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/callunasansregular-webfont.woff') format('woff');
}
body {
	background: url(//subtlepatterns.com/patterns/scribble_light.png);
  font-family: 'Calluna', 'Arial', 'sans-serif';
  min-height: 1000px;
}
#searchBar2{
  height: 1.5rem;
  margin-top: 15px;
}

.input_field_2{
   max-width: "900pt";
   height:40; min-width: "25vw"; color:"whitesmoke"; display: "flex"; margin-right: "2vw" }