.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(44, 43, 43);
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 10px 7px;
    margin-top: 10px;
    margin-left: -45px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }