*{
    margin: 0;
    padding: 0;
    box-sizing: border-box
}
body {
    font-family: 'DejaVu Serif Bold', 'Oswald', 'sans-serif';
    font-size: ;
    background: #131212;
    background: #212020;
}

a {
    color: #333;
    color: whitesmoke;
    text-decoration: none;
}
.map_select {
    display: inline;
    height: 1.5rem;
    padding: 0;
}
#map_range_input{
    margin:0;
    border: none;
}
.sci-main-container {
    display: grid;
    grid-template-columns:  minmax(200px, 250px) 1fr minmax(250px, 250px);
    grid-template-rows: 1fr;
    grid-gap: 0px;
  /*   background: #f1f1f1; */
  background: #131212;
  background: #212020;

    margin-bottom: 1px;
    grid-template-areas: "left-nav sci-content right-nav"; 
}
#left-nav {
    grid-area: left-nav;
    position: sticky;
    top: 5vh;
    height: 85vh;
    box-sizing: border-box;
}
#right-nav{
    grid-area: right-nav;
    position: sticky;
    top: 5vh;
    height: 90vh;
    box-sizing: border-box;
    overflow: scroll;
}

.sci-container {
    overflow: auto;
    padding: 0 0rem;
    grid-area: sci-content;
}
.main-header {
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
}

.main-header h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    line-height: 1.2;
}
.main-header h1 span{
    color: royalblue;
}
.main-header p {
    font-size: 2rem;
}

.sci-card-content{
padding:17px 10px 17px 20px;
font-family:Helvetica, Arial, sans-serif;
font-size:14px;
line-height:18px;
color:#292F33;
}

.sci-card {
    display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 2rem;
    background: #131212;
   /*  background: #f1f1f1; */
    margin-bottom: 1px;
    grid-template-areas: "content content image"; 
}
.sci-card h3 {
    margin-bottom: 2rem;
}
.sci-card>  img {
    grid-area: image;
    max-height: 250px;
}
.sci-card>.sci-card-content {
    grid-area: content;
}
.sci-card:nth-child(2n) {
    grid-template-areas: "image content content"
}

/* 
.sci-card:nth-child(even) div {
grid-column: -1/-3;
} 
.sci-card:nth-child(odd) img {
order: 2;
} */

.sci-btn-top-head{
    display: inline-block;
    border-style: none 1px none none;
    color:rgba(17, 8, 42, 0.9);
    color: whitesmoke;
    padding:  .2rem ;
    margin: 2px;
    background:#d0d0c8;
    background: #4d4c4c;
   /*  background: #212020; */
    cursor: pointer;
    transition: all .6s;
    letter-spacing: 1.3px;
    border-right: 1px solid gray;
    text-transform: capitalize;
    font-size: 15px;
    
    
}
.sci-btn-top-head:hover {
    border: 1px solid gray;
    
}
.sci-btn {
    display: inline-block;
    background: whitesmoke;
    background: #131212;
    background: #2b2828;
   /*  background: #212020; */
    color: rgba(6, 5, 97, 0.897);
    color: whitesmoke;
    padding:  .2rem ;
    cursor: pointer;
    border-style: none 1px none none;
    border-color: white;
    border-radius: 1px;
}
.sci-btn:hover {
    opacity: .8;
    color: red;
    color: yellowgreen;
    border-style: solid;
    border-color: whitesmoke;
    
}
@media(max-width: 700px) {
    .sci-card{
        display: block;
    }
}
.news-container{
    /* margin-bottom: 10px; */
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow: scroll;
}

#news_heads {
    background-color:whitesmoke;
    background: #131212;
    background:#212020 ;

    font-weight: bold;
    padding-top: -2vh;
    height: 100vh;
}
#news_heads a {
    color: #1940b4;
    color: whitesmoke;
    font-weight: bold;
}
.news_head{
    text-transform: capitalize;
    text-decoration:none;
}
.news_head_btn{
    background: white;
    background: #131212;
    background: #201d1d;
    background:#212020 ;
    background: #272828;
    color: #000;
    color: whitesmoke;
    float: left;
    font-weight: 500;
    line-height: 100%;
    font-size: 12px;;
    padding: 8px 10px;
    text-transform: capitalize;
    transition: all .6s;
    letter-spacing: 1.3px;
    font-family: 'Open Sans', sans-serif;
    margin: 2px;
    cursor: pointer;
    opacity: .75;
}
.top-nav-btn{
    /* background: white;
    background-color: black; */
    color: #000;
    color: whitesmoke;
    float: left;
    font-weight: 600;
    line-height: 100%;
    /* font-size: 15px;; */
   padding: 5px 0px;
    text-transform: capitalize;
    transition: all .6s;
    letter-spacing: 1.3px;
    font-family: 'Open Sans', sans-serif;
    margin: 10px 2rem;
    cursor: pointer;
}
.top-nav-btn:hover {
    border-bottom: 2px solid rgb(38, 107, 32);
    /* background-color: #d4d447; */
}
.main_news_head {
    font-size: 18px;
    margin-top: 10px;
    background: #131212;
    background:#212020 ;
    cursor: pointer;
    font-family: 'DejaVu Serif Bold';
    
}
#news_card_content{
padding:5px 10px 5px 15px;
font-family:Helvetica, Arial, sans-serif;
font-size:14px;
line-height:18px;
color:#292F33;
color: #C8D5DD;
}

#news_card_title{
padding:0px;
font-family:Helvetica, Arial, sans-serif;
font-size: 16px;
/* line-height:20px;
font-weight:bold; */
color:rgb(62, 65, 66);
color:#C8D5DD;
font-style: italic
}

#searchBar, #urlBar, #wikiBar,#webBar, #predictiveBar {
    color: whitesmoke;
}
#form1{
  margin-left:200px;
}
.side-nav li a {
  text-decoration:none;
}
#searchResults2{
  background-color:#666666;
}
#searchResults{
  background-color:#666666;
}
.right li a{
  padding-right:2em;
  text-decoration:none;
  color: black;
}
.right li {
    margin-top: 5px;
    padding: 5px;
    font-size: 1rem;
}
